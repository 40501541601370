import { Container } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import AddCampaign from "pages/AddCampaign";
import React from "react";
import Tabs from "components/templates/Tabs";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { campaignActions } from "redux/slices/campaign/campaignSlice";
import CoupanManagement from "./CoupanManagement";

const CampaignUpdate = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const tab = useAppSelector((state) => state.campaign.tab);
  return (
    <div>
      <Container maxWidth="lg">
        <GoBack path="/campaigns" title="Campaigns" />
        <Banner heading="Update Campaign" />
        <Tabs
          value={tab}
          onChange={(tab) => dispatch(campaignActions.setTab(tab))}
          tabs={[
            {
              label: "Edit",
              element: <AddCampaign />,
            },
            {
              label: "Coupon",
              element: <CoupanManagement />,
            },
          ]}
        />
      </Container>
    </div>
  );
};

export default CampaignUpdate;
