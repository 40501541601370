import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { zipcodeActions } from "redux/slices/zipcode/zipcodeSlice";
import RoleService from "services/role.service";
import { roleactions } from "redux/slices/role/roleSlice";
import SearchService from "services/search.service";
import { searchActions } from "redux/slices/popularSearches/popularSearchesSlice";

export default function UpdatePopularSearchesForm({ id }: any) {
  const form = "AddPopularSearchesForm";
  const dispatch = useAppDispatch();
  const singleSearch = useAppSelector((state) => state.search.singleSearch);

  useEffect(() => {
    SearchService.getSearchByID(`${id}`, dispatch);
    return () => {
      dispatch(searchActions.setSingleSearch(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!singleSearch || singleSearch?.data === "Not Found") return;
    const { order, searches } = singleSearch;
    dispatch(change(form, "searches", searches));
    dispatch(change(form, "order", order));
  }, [dispatch, singleSearch]);

  return null;
}
