import FiltersForm from "components/templates/FiltersForm";
import { productActions } from "redux/slices/product/productSlice";
import { fields } from ".";
import { reset } from "redux-form";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { SubCategoryService } from "services/subcategories.service";
import LocalStorage from "utils/localstorage.util";

export default function ProductFilters() {
  const dispatch = useAppDispatch();
  const form = "productFiltersForm";
  const category = useAppSelector(
    (state) => state.form?.[form]?.values?.mainCategory
  );

  useEffect(() => {
    if (category)
      SubCategoryService.getSubCategoriesByCategoryId(category, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    return () => {
      dispatch(reset(form));
      dispatch(productActions.setFilter({}));
    };
  }, [dispatch]);

  const handleSubmit = (values: any) => {
    const data = { ...values };

    if (data.pageSize) data.pageSize = Number(data.pageSize);

    dispatch(productActions.setFilter(data));

    LocalStorage.setItem(form, data);
  };

  return (
    <>
      <FiltersForm
        onSubmit={handleSubmit}
        myFields={fields}
        form={form}
        onClickReset={() => {
          dispatch(reset(form));
          LocalStorage.setItem(form, "");
          dispatch(productActions.setFilter({}));
        }}
      />
    </>
  );
}
