import { OrderState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: OrderState = {
  orders: [],
  rejectedOrders: [],
  selectedOrders: [],
  mostOrders: [],
  activeOrders: [],
  todayOrders: [],
  orderDetails: null,
  orderStatus: null,
  assignOrder: null,
  loading: true,
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders: (
      state,
      action: PayloadAction<{ allOrders: any[]; count: number }>
    ) => {
      const { allOrders, count } = action.payload;
      state.orders = allOrders;
      state.count = count;
      state.refreshLoader = false;
    },
    setActiveOrders: (
      state,
      action: PayloadAction<{ activeOrders: any[]; count: number }>
    ) => {
      const { activeOrders, count } = action.payload;
      state.activeOrders = activeOrders;
      state.refreshLoader = false;
      state.count = count;
    },
    setTodayOrders: (
      state,
      action: PayloadAction<{ todayOrders: any[]; count: number }>
    ) => {
      const { todayOrders, count } = action.payload;
      state.todayOrders = todayOrders;
      state.refreshLoader = false;
      state.count = count;
    },
    setorderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    clearSelectedOrders: (state) => {
      state.selectedOrders = [];
    },
    selectOrder: (state, action) => {
      const order = action.payload;
      state.selectedOrders = [...state.selectedOrders, order];
    },
    deselectOrder: (state, action) => {
      state.selectedOrders = state.selectedOrders.filter(
        ({ orderId }) => orderId !== action.payload
      );
    },
    selectAllOrders: (state, action) => {
      state.selectedOrders = action.payload;
    },
    deselectAllOrders: (state) => {
      state.selectedOrders = [];
    },
    setorderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
    setassignOrder: (state, action) => {
      state.assignOrder = action.payload;
    },
    setRejectedOrders: (
      state,
      action: PayloadAction<{ rejectedOrders: any[]; count: number }>
    ) => {
      const { rejectedOrders, count } = action.payload;
      state.rejectedOrders = rejectedOrders;
      state.count = count;
      state.refreshLoader = false;
    },
    setMostOrders: (
      state,
      action: PayloadAction<{ mostOrders: any[]; count: number }>
    ) => {
      const { mostOrders, count } = action.payload;
      state.mostOrders = mostOrders;
      state.count = count;
      state.refreshLoader = false;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPaymentStatus: (state, action: PayloadAction<string>) => {
      state.orderStatus = action.payload;
    },
  },
});

const orderReducer = orderSlice.reducer;

export const orderActions = orderSlice.actions;
export default orderReducer;
