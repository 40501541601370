import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { required } from "utils/validate.util";

export { default } from "./AddPopularSearchesForm";

export const fields: ReduxFormField[] = [
  {
    name: "order",
    label: "Display Order",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
  {
    name: "searches",
    label: "Name",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 6 },
  },
];
