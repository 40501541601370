import SearchSelect from "components/atoms/SearchSelect";

export default function ComboBoxRedux({
  input,
  label,
  InputProps,
  handleBlur,
  handleFocus,
  meta: { error, touched, invalid },
  ...rest
}: any) {
  const { onChange, ...inputRest } = input;

  return (
    <SearchSelect
      {...rest}
      value={input?.value || []}
      onChange={(_e: any, value: any) => onChange(value)}
      InputProps={{
        label,
        ...InputProps,
        ...inputRest,
        helperText: touched && invalid && error,
        error: touched && invalid && error && true,
        onBlur: (e: { preventDefault: () => void }) => {
          handleBlur?.(e);
          e.preventDefault();
        },
        onFocus: (e: { preventDefault: () => void }) => {
          handleFocus?.(e);
          e.preventDefault();
        },
      }}
    />
  );
}
