import http from "./http.service";
import { AppDispatch } from "redux/store";
import { productActions } from "redux/slices/product/productSlice";
import PromiseAble from "./promiseable.service";
import { NavigateFunction } from "react-router-dom";
import { modalActions } from "redux/slices/modal";
import { formLoaderActions } from "redux/slices/formLoader";
import { SelectOption } from "components/atoms/Select";

const url = "/product";

const ProductsService = {
  getProductbyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { product } = success.data.data;
      dispatch?.(productActions.setProduct(product));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getProducts: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(productActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );

    if (success) {
      const { products, count } = success.data.data;
      dispatch?.(productActions.setProducts({ products: products, count }));
    }

    dispatch?.(productActions.setLoading(false));

    return [success, error];
  },

  getProductOptions: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(productActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );

    if (success) {
      const { products } = success.data.data;
      let options: SelectOption[] = [];
      products.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      dispatch?.(productActions.setProductOptions({ options }));
    }

    dispatch?.(productActions.setLoading(false));

    return [success, error];
  },

  exportProducts: async (filters: any, dispatch?: AppDispatch) => {
    http.setJWT();

    dispatch?.(modalActions.setLoading(true));
    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/export`, { filters })
    );

    if (success) {
      const { products } = success.data.data;
      dispatch?.(
        modalActions.updateData({
          table_data: products.map((product: any) => ({
            "Product ID": product.productId,
            "Product Name - EN": product.translations[0]?.name,
            "Product Name - DE": product.translations[1]?.name,
            Category: product.categories?.length
              ? product.categories
                  .map(
                    (c: any) =>
                      c?.category?.parentCategoryId?.translations?.[0]
                        ?.translation
                  )
                  .join(" | ")
              : "Missing",
            "Sub-Category": product.categories?.length
              ? product.categories
                  .map((c: any) => c?.category?.translations?.[0]?.translation)
                  .join(" | ")
              : "Missing",
            Images: product.images?.length
              ? product.images.map((img: any) => img.image).join(" | ")
              : "Missing",
            "Is Published": product.isPublished,
            "Prevent Under 16": product.preventUnder16,
            "Prevent Under 18": product.preventUnder18,
            "Stock Type": product.stockType,
            Tags: product.tags.length
              ? product.tags.map((c: any) => c).join(" | ")
              : "",
            "Tax %": product.taxPercentage,
            "Maximum Buyable Count": product.maximumBuyableCount,
            "Display Order": product.displayOrder,
            Price: product.newPrice,
            "Old Price": product.oldPrice,
            "Product Primary Property - EN":
              product.translations[0]?.primaryProperty,
            "Product Primary Property - DE":
              product.translations[1]?.primaryProperty,
            "Product Description - EN": product.translations[0]?.longDesc,
            "Product Description - DE": product.translations[1]?.longDesc,
            "Product Nutrition - EN": product.translations[0]?.nutrition,
            "Product Nutrition - DE": product.translations[1]?.nutrition,
            "Product Ingredients - EN": product.translations[0]?.ingredients,
            "Product Ingredients - DE": product.translations[1]?.ingredients,
            "Product Allergies - EN": product.translations[0]?.allergies,
            "Product Allergies - DE": product.translations[1]?.allergies,
            "Product Manufacturer - EN": product.translations[0]?.manufacturer,
            "Product Manufacturer - DE": product.translations[1]?.manufacturer,
          })),
        })
      );
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  addProduct: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );
    if (success) {
      const product = success.data.data.product;
      dispatch?.(productActions.addProduct(product));
      navigate?.(`/update-product/${product._id}`);
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateProduct: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      const product = success.data.data.updated_product;
      dispatch?.(productActions.updateProduct(product));
      dispatch?.(productActions.setShow("list"));
      dispatch?.(modalActions.closeModal());
    }
    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  deleteProduct: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(productActions.deleteProduct(id));
    }
    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  updateStock: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(productActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/${data.productId}/update-stock`, data)
    );

    if (success) {
      const { updated_product } = success.data.data;
      // dispatch?.(modalActions.closeModal());
      // dispatch?.(productActions.deleteProduct(id));
      dispatch?.(productActions.updateProduct(updated_product));
      dispatch?.(productActions.setLoading(false));
    }
    dispatch?.(productActions.setLoading(false));
    return [success, error];
  },
};

export default ProductsService;
