import { DeliveryFeeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: DeliveryFeeState = {
  deliveryFee: [],
  loading: true,
  singleDeliveryFee:null
};

export const deliveryFeeSlice = createSlice({
  name: "deliveryFee",
  initialState,
  reducers: {
    setDeliveryFee: (state, action) => {
      state.deliveryFee = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSingleDelivery:(state,action)=>{
      state.singleDeliveryFee = action.payload
    }
  },
});

const deliveryFeeReducer = deliveryFeeSlice.reducer;

export const deliveryFeeActions = deliveryFeeSlice.actions;
export default deliveryFeeReducer;
