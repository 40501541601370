import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import productReducer from "./slices/product/productSlice";
import categoryReducer from "./slices/category/categorySlice";
import zipcodeReducer from "./slices/zipcode/zipcodeSlice";
import storeReducer from "./slices/store/storeSlice";
import faqReducer from "./slices/faq/faqSlice";
import subCategoryReducer from "./slices/subcategory";
import bannerSlice from "./slices/banner/bannerSlice";
import campaignReducer from "./slices/campaign/campaignSlice";
import formLoaderReducer from "./slices/formLoader";
import deliveryFeeReducer from "./slices/deliveryFee/deliveryFeeSlice";
import roleReducer from "./slices/role/roleSlice";
import orderReducer from "./slices/order/orderSlice";
import couponReducer from "./slices/coupon/couponSlice";
import dashboardReducer from "./slices/dashboard/dashboardSlice";
import searchReducer from "./slices/popularSearches/popularSearchesSlice";
import dealReducer from "./slices/deal/dealSlice";

const appReducer = combineReducers({
  form: formReducer,

  subCategory: subCategoryReducer,
  auth: authReducer,
  modal: modalReducer,
  loader: loaderReducer,
  deliveryFee: deliveryFeeReducer,
  product: productReducer,
  store: storeReducer,
  zipcode: zipcodeReducer,
  category: categoryReducer,
  faq: faqReducer,
  banner: bannerSlice,
  campaign: campaignReducer,
  role: roleReducer,
  formLoader: formLoaderReducer,
  order: orderReducer,
  coupon: couponReducer,
  dashboard: dashboardReducer,
  search: searchReducer,
  deal: dealReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
