const DeliveryFeeCard = ({ data }: any) => {
  return (
    <div>
      {/* <div className="header">
        <div className="order__heading">Delivery Info</div>
      </div> */}
      <div className="details-fee">
        <ul className="order_ul">
          <li>
            <div className="left">Delivery Fee</div>
            <div className="right">{data?.deliveryFee?.toFixed(2)}€</div>
          </li>
          <li>
            <div className="left">Free Delivery On</div>
            <div className="right">{data?.freeDeliveryOn?.toFixed(2)}€</div>
          </li>
          <li>
            <div className="left">Minimum Weight</div>
            <div className="right">{data?.minimumWeight?.toFixed(2)}kg</div>
          </li>
          <li>
            <div className="left">Delivery Fee After Minimum Weight</div>
            <div className="right">
              {data?.deliveryFeeAfterMinimumWeight?.toFixed(2)}€
            </div>
          </li>

          <li>
            <div className="left">Free Delivery On After minimum Weight</div>
            <div className="right">
              {data?.freeDeliveryOnAfterMinimumWeight?.toFixed(2)}€
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DeliveryFeeCard;
