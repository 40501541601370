import { Container, Grid, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./deliveryManagement.css";
import DeliveryFeeService from "services/deliveryFee.service";
import DeliveryFeeCard from "./DeliveryFeeCard";
import { AiOutlineEdit } from "react-icons/ai";

const DeliveryFeeManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deliveryFees = useAppSelector((state) => state.deliveryFee.deliveryFee);

  useEffect(() => {
    DeliveryFeeService.getDeliveryFee(dispatch);
  }, [dispatch]);

  return (
    <Container maxWidth="md">
      <Banner heading={`Delivery Fee Management`}>
        <Stack direction="row" spacing={2}>
          {(deliveryFees || []).length > 0 && (
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                navigate(
                  `/update-delivery-fee/${
                    deliveryFees?.length > 0 && deliveryFees[0]?._id
                  }`
                )
              }
            >
              <AiOutlineEdit /> &nbsp; Update fee
            </Button>
          )}
        </Stack>
      </Banner>
      <Grid container columnSpacing={4}>
        <Grid item md={12}>
          <BasicCard>
            {" "}
            <div className="details-fee">
              <ul className="order_ul">
                <li>
                  <div className="left">Minimum Order Limit</div>
                  <div className="right">
                    {deliveryFees?.length > 0 &&
                      deliveryFees[0]?.minimumOrderLimit?.toFixed(2)}
                    €
                  </div>
                </li>
              </ul>
            </div>
          </BasicCard>
        </Grid>
        <Grid item md={6}>
          <BasicCard>
            <h3>Berlin Delivery Info</h3>
            <DeliveryFeeCard
              data={
                deliveryFees?.length > 0 && deliveryFees[0]?.berlinDeliveryFee
              }
            />
          </BasicCard>
        </Grid>
        <Grid item md={6}>
          <BasicCard>
            <div style={{ textAlign: "center" }}>
              <h3>Germany Delivery Info</h3>
              <DeliveryFeeCard
                data={
                  deliveryFees?.length > 0 &&
                  deliveryFees[0]?.germanyDeliveryFee
                }
              />
            </div>
          </BasicCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeliveryFeeManagement;
