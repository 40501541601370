import OrderService from "services/order.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import OrdersList from "pages/Orders/OrdersList";

export default function TodayOrderList() {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const todayOrders = useAppSelector((state) => state.order.todayOrders);
  const loading = useAppSelector((state) => state.order.loading);
  const filter = useAppSelector((state) => state.order.filter);
  const refresh = useAppSelector((state) => state.order.refresh);
  const refreshLoader = useAppSelector((state) => state.order.refreshLoader);

  const orderstatus = useAppSelector(
    (state) => state.form?.ChangeOrderStatusForm?.values
  );

  const assign = useAppSelector(
    (state) => state.form?.ShopperSelection?.values
  );

  useEffect(() => {
    OrderService.getTodayOrders(filter, dispatch);
  }, [filter, refresh, dispatch, orderstatus, assign]);

  return (
    <>
      {todayOrders?.length > 0 && (
        <OrdersList
          orders={todayOrders}
          loading={loading}
          refreshLoader={refreshLoader}
        />
      )}
    </>
  );
}
