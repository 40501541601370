import Select from "components/atoms/Select";
import { useAppDispatch } from "redux/hooks";
import OrderService from "services/order.service";

const OrderStatus = ({ id, status }: any) => {

    const dispatch = useAppDispatch();

    const handleOrderStatusChange = (e: any) => {
        OrderService.changeOrderStatus(id, {currentStatus: e.target.value}, dispatch);
        console.log(
            "🚀 ~ file: OperationsCard.tsx:22 ~ handleOrderSubmit ~ values:",
            e.target.value
        );
    };

    return (
        <Select onChange={handleOrderStatusChange} defaultValue={status} options={[
            { value: "status_order_draft", label: "Draft Order " },
            { value: "status_ready_to_be_packed", label: "Ready To Be Packed " },
            {
                value: "status_currently_being_picked",
                label: "Currently Being Picked ",
            },
            { value: "status_partially_ready", label: "Partially Ready " },
            { value: "status_order_ready", label: "Order Ready " },
            { value: "status_out_for_delivery", label: "Out For Delivery" },
            { value: "status_delivered", label: "Completed" },
            { value: "status_delivery_failed", label: "Cancelled " },
        ]} />
    )

};

export default OrderStatus;

