import { fields } from ".";
import FiltersForm from "components/templates/FiltersForm";
import { useAppDispatch } from "redux/hooks";
import format from "date-fns/format";
import { useEffect } from "react";
import { reset } from "redux-form";
import { orderActions } from "redux/slices/order/orderSlice";

export default function OrdersFilters({ isTodayOrder }: any) {
  const dispatch = useAppDispatch();
  const form = "orderFilterForm";
  useEffect(() => {
    return () => {
      dispatch(reset(form));
      dispatch(orderActions.setFilter({}));
    };
  }, [dispatch]);

  const handleSubmit = (values: any) => {
    const data = { ...values };
    if (data.date?.date[0]) {
      data.startDate = format(
        new Date(data.date.date[0]),
        "yyyy-MM-dd HH:mm:ss.SSSSSS"
      );
      data.endDate = format(
        new Date(data.date.date[1]),
        "yyyy-MM-dd HH:mm:ss.SSSSSS"
      );
    }

    if (data.pageSize) data.pageSize = Number(data.pageSize);
    if (data.deliveryArea) {
      data.deliveryArea === "berlin"
        ? (data.deliveryInfo = { $ne: null })
        : (data.deliveryInfo = null);
    }
    if (data.minimumCart) data.minimumCart = Number(data.minimumCart);
    if (data.maximumCart) data.maximumCart = Number(data.maximumCart);

    delete data.date;
    delete data.deliveryArea;

    dispatch(orderActions.setFilter(data));
  };

  return (
    <FiltersForm
      onSubmit={handleSubmit}
      myFields={fields(isTodayOrder)}
      form={form}
      onClickReset={() => {
        dispatch(orderActions.setFilter({}));
        dispatch(reset(form));
      }}
    />
  );
}
