import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Grid,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { useAppSelector } from "redux/hooks";
import { useAppDispatch } from "redux/hooks";
import { useEffect } from "react";
import ProductAnalyticsService from "services/product-analytics.service";
import { Link } from "react-router-dom";
import { productActions } from "redux/slices/product/productSlice";

export default function MSProductCountFullList() {
  const dispatch = useAppDispatch();

  const msProducts = useAppSelector((state) => state.product.msProducts);

  const loading = useAppSelector((state) => state.product.loading);
  const refresh = useAppSelector((state) => state.product.refresh);
  const refreshLoader = useAppSelector((state) => state.product.refreshLoader);

  useEffect(() => {
    dispatch(productActions.setMSProducts({ products: [] }));
    ProductAnalyticsService.getMostSellingProducts(dispatch, true);
  }, [refresh, dispatch]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <div className="card">
            <div>
              <p>
                <b>Most Selling Products Full List</b>
              </p>
              <TableContainer>
                <Table
                  aria-label="customized table"
                  sx={{
                    minWidth: "100%",
                    borderSpacing: "0 10px",
                    borderBottomWidth: "0px",
                    borderCollapse: "separate",
                  }}
                >
                  <TableLoadingWrapper
                    container
                    coloumns={3}
                    loading={loading}
                    message="No products available"
                    length={refreshLoader ? 0 : msProducts.length}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID</StyledTableCell>
                        <StyledTableCell>Product Name</StyledTableCell>
                        <StyledTableCell>Count</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {msProducts.map((p: any, i: number) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{p.productId}</StyledTableCell>
                          <StyledTableCell>
                            <Link
                              to={`/update-product/${p._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              {p.productName}
                            </Link>{" "}
                          </StyledTableCell>
                          <StyledTableCell>{p.totalSold}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </TableLoadingWrapper>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
