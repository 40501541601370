import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { reduxForm } from "redux-form";
import { fields } from ".";

const AddPopularSearchesForm = ({ handleSubmit }: any) => {
  return (
    <form onSubmit={handleSubmit}>
      <ReduxFormFields fields={fields} />
      <br />
      <Button color="primary" variant="contained" type="submit">
        Submit
      </Button>
    </form>
  );
};

export default reduxForm({
  form: "AddPopularSearchesForm",
})(AddPopularSearchesForm);
