import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";

import { BsTrashFill } from "react-icons/bs";

import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import Button from "components/atoms/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { MODAL } from "redux/slices/modal";
import { modalActions } from "redux/slices/modal";
import CouponService from "services/coupon.service";
import SwitchButton from "components/atoms/SwitchButton";
import SubscriptionService from "services/subscription.service";

const CoupanManagementList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.coupon.loading);
  const coupons = useAppSelector((state) => state.coupon.coupons);
  const { id } = useParams();

  useEffect(() => {
    CouponService.getCoupons(id, dispatch);
  }, [dispatch]);




  return (
    <div>
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={2}
            loading={loading}
            message="No categories available"
            length={coupons?.length}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Coupon Code</StyledTableCell>
                <StyledTableCell>Is Active?</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons.map((coupon: any, i: number) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{coupon.code}</StyledTableCell>
                  <StyledTableCell
                  >
                    <SwitchButton
                      label={coupon.isActive === true ? "Active" : "Inactive"}
                      checked={coupon.isActive === true ? true : false}
                      onChange={(e: any) => {
                        const checked = e.target.checked;
                        const isActive = checked ? true : false;

                        // SubscriptionService.subscribe(() => {
                        //   CouponService.updateCoupon(id, coupon._id, {
                        //     status,
                        //   });
                        // });
                        dispatch(
                          modalActions.openModal({
                            width: "500px",
                            type: MODAL.CONFIRMATION_FORM,
                            data: {
                              isActive,
                              compaignID: id,
                              id: coupon._id,
                              type: MODAL.UPDATE_COUPON,
                              heading: "Update Coupon Status",
                              message: `Do you really want to Change status of that coupon?`,
                            },
                          })
                        );
                      }}
                    />
                  </StyledTableCell>

                  <StyledTableCell></StyledTableCell>

                  <StyledTableCell align="right">
                    <Button
                      // size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() =>
                        dispatch(
                          modalActions.openModal({
                            width: "500px",
                            type: MODAL.CONFIRMATION_FORM,
                            data: {
                              id: coupon._id,
                              compaignID: id,
                              type: MODAL.DELETE_COUPON,
                              heading: "Delete Coupon",
                              message:
                                "Do you really want to delete this Coupon?",
                            },
                          })
                        )
                      }
                      sx={{ minWidth: "auto" }}
                    >
                      <BsTrashFill />
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CoupanManagementList;
