import React, { useEffect } from "react";

import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import { AiOutlineEdit } from "react-icons/ai";

import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import Button from "components/atoms/Button";
import SearchService from "services/search.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useNavigate } from "react-router-dom";

const PopularSearchesList = () => {
  const dispatch = useAppDispatch();
  const searches = useAppSelector((state) => state.search.searches);
  const loading = useAppSelector((state) => state.search.loading);
  const navigate = useNavigate();
  useEffect(() => {
    SearchService.getSearches(dispatch);
  }, [dispatch]);
  return (
    <div>
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={3}
            loading={loading}
            message="No Searches available"
            length={1}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Display Order</StyledTableCell>
                <StyledTableCell>Searches</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searches.map((data: any, i: number) => (
                <StyledTableRow key={i}>
                  <StyledTableCell style={{ maxWidth: "200px" }}>
                    {data.order}
                  </StyledTableCell>
                  <StyledTableCell style={{ maxWidth: "200px" }}>
                    {data.searches}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Stack direction="row" spacing={2} justifyContent="right">
                      {/* <Button
                        // size="large"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() =>
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.CONFIRMATION_FORM,
                              data: {
                                id: data._id,
                                type: MODAL.DELETE_FAQ,
                                heading: "Delete FAQ",
                                message:
                                  "Do you really want to delete this FAQ?",
                              },
                            })
                          )
                        }
                        sx={{ minWidth: "auto" }}
                      >
                        <BsTrashFill />
                      </Button> */}
                      <Button
                        // size="large"
                        variant="contained"
                        color="secondary"
                        disableElevation
                        onClick={() => navigate(`/update-search/${data._id}`)}
                        sx={{ minWidth: "auto" }}
                      >
                        <AiOutlineEdit />
                      </Button>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PopularSearchesList;
