import { SelectOption } from "components/atoms/Select";
import { StoreState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: StoreState = {
  stores: [],
  loading: true,
  store: null,
  storeMap: {},
  storeOptions: [],
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const storeSlice = createSlice({
  name: "storemangement",
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
    },
    setStores: (
      state,
      action: PayloadAction<{ stores: any[]; count: number }>
    ) => {
      const { stores, count } = action.payload;
      state.stores = stores;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];

      let storeMap: any = {};

      stores.forEach((store: any) => {
        const { _id, name } = store;
        storeMap[_id] = store;
        options.push({ value: _id, label: name });
      });

      state.stores = stores;
      state.storeMap = storeMap;
      state.storeOptions = options;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },

    addStore: (state, action) => {
      const store = action.payload;
      state.stores = [...state.stores, store];
    },

    deleteStore: (state, action) => {
      const storeId = action.payload;
      state.stores = state.stores.filter((store) => {
        return store._id !== storeId;
      });
    },
    updateStore: (state, action) => {
      const { id, store } = action.payload;
      state.stores.every(({ _id }, i) => {
        if (id === _id) {
          state.stores[i] = store;
          state.storeMap[_id] = store;
          return false;
        }
        return true;
      });
    },

    filterStoreZipcode: (
      state,
      action: PayloadAction<{ id: string; zipcode_id: string }>
    ) => {
      const { id, zipcode_id } = action.payload;
      state.stores.every(({ _id }, i) => {
        if (_id === id) {
          state.stores[i].zipCodes = state.stores[i].zipCodes.filter(
            (zipcode: any) => zipcode !== zipcode_id
          );
          state.storeMap[id] = state.stores[i];
          return false;
        }
        return true;
      });
    },
  },
});

const storeReducer = storeSlice.reducer;

export const storeActions = storeSlice.actions;
export default storeReducer;
