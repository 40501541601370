import { DashboardState } from ".";
import {
  createSlice, PayloadAction,
} from "@reduxjs/toolkit";

const initialState: DashboardState = {
  details: [],
  loading: true,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardDetails: (state, action) => {
      state.details = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const dashboardReducer = dashboardSlice.reducer;
export const dashboardActions = dashboardSlice.actions;
export default dashboardReducer;
