import { Container } from "@mui/material";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import ProductStockList from "./ProductStockList";
import Pagination from "components/organisms/Pagination/Pagination";
import { productActions } from "redux/slices/product/productSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ProductFilters from "pages/ProductManagement/ProductFilters";

export default function ProductStockManagement() {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.product.filter);
  const count = useAppSelector((state) => state.product.count);
  const refreshLoader = useAppSelector((state) => state.product.refreshLoader);

  return (
    <Container maxWidth="lg">
      <Banner heading={`Product Stock Management (${count})`}></Banner>
      <BasicCard>
        <ProductFilters />
      </BasicCard>
      {!refreshLoader && count > 0 && (
        <Pagination
          page={filter.page}
          count={Math.ceil(count / filter.pageSize)}
          onChange={(_e, page) => dispatch(productActions.setPage(page))}
        />
      )}

      <br />
      <BasicCard>
        <ProductStockList />
      </BasicCard>
    </Container>
  );
}
