import { formLoaderActions } from "./../redux/slices/formLoader/formLoaderSlice";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { subCategoryActions } from "redux/slices/subcategory";
import { NavigateFunction } from "react-router-dom";
import { modalActions } from "redux/slices/modal";

const url = "/subcategories";

const SubCategoryService = {
  getSubCategorybyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { SubCategory } = success.data.data;
      dispatch?.(subCategoryActions.setSubCategory(SubCategory));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getAllSubCategories: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(subCategoryActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );
    if (success) {
      const { data, count } = success.data.data;
      dispatch?.(subCategoryActions.setSubCategories({ data: data, count }));
    }
    dispatch?.(subCategoryActions.setLoading(false));
    return [success, error];
  },
  getSubCategoriesByCategoryId: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));
    let filters = {
      ParentCategory: id,
      pageSize: 10000000,
      page: 1,
    };
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );
    if (success) {
      const { data } = success.data.data;

      dispatch?.(subCategoryActions.setSelectedSubSubCategories({ data }));
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  addSubCategory: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const category = success.data.data;
      dispatch?.(subCategoryActions.addSubCategory(category));

      navigate?.("/subcategory-management");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteSubCategory: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(subCategoryActions.filterSubCategory(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateSubCategory: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      const SubCategory = success.data.data;

      dispatch?.(subCategoryActions.updateSubCategory({ id, SubCategory }));
      navigate?.("/subcategory-management");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export { SubCategoryService };
