import { Button as BaseButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const findType = (type: string) => {
  let value = "all";
  switch (type) {
    case "/active-orders":
      value = "active";
      break;
    case "/failed-orders":
      value = "failed";
      break;
    case "/today-orders":
      value = "today";
      break;
    default:
      break;
  }
  return value;
};

export default function OrderButton() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let [color, setColor] = useState("all");

  const handleClick = (type: string) => {
    if (type === "active") {
      setColor(() => (color = "active"));
      return navigate("/active-orders");
    } else if (type === "today") {
      setColor(() => (color = "today"));
      return navigate("/today-orders");
    } else if (type === "failed") {
      setColor(() => (color = "failed"));
      return navigate("/failed-orders");
    } else if (type === "all") {
      setColor(() => (color = "all"));
      return navigate("/orders");
    }
  };

  useEffect(() => {
    setColor(() => (color = findType(pathname)));
  }, [pathname]);

  return (
    <>
      <BaseButton
        style={{
          borderRadius: 0,
          textTransform: "capitalize",
          borderBottom: color === "today" ? "1px solid #C93375" : "",
        }}
        variant="text"
        disableElevation
        onClick={() => handleClick("today")}
      >
        Today Orders
      </BaseButton>
      <BaseButton
        style={{
          borderRadius: 0,
          textTransform: "capitalize",
          borderBottom: color === "active" ? "1px solid #C93375" : "",
        }}
        variant="text"
        disableElevation
        onClick={() => handleClick("active")}
      >
        Active Orders
      </BaseButton>
      <BaseButton
        style={{
          borderRadius: 0,
          textTransform: "capitalize",
          borderBottom: color === "all" ? "1px solid #C93375" : "",
        }}
        variant="text"
        disableElevation
        onClick={() => handleClick("all")}
      >
        All Orders
      </BaseButton>
      <BaseButton
        style={{
          borderRadius: 0,
          textTransform: "capitalize",
          borderBottom: color === "failed" ? "1px solid #C93375" : "",
        }}
        variant="text"
        disableElevation
        onClick={() => handleClick("failed")}
      >
        Failed Orders
      </BaseButton>
      <br />
      <br />
    </>
  );
}
