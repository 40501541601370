import { CouponState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const initialState: CouponState = {
  coupons: [],
  coupon: null,
  loading: true,
};

export const couponSlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    setCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    filterCoupon: (state, action) => {
      const id = action.payload;
      state.coupons = state.coupons.filter(({ _id }) => _id !== id);
    },
    updateCoupon: (state, action) => {
      const { id, coupon } = action.payload;
      state.coupons.every(({ _id }, i) => {
        if (id === _id) {
          state.coupons[i] = coupon;
          return false;
        }
        return true;
      });
    },
  },
});

const couponReducer = couponSlice.reducer;

export const couponActions = couponSlice.actions;
export default couponReducer;
