import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { length5, required } from "utils/validate.util";

export { default } from "./AddCouponForm";

export const fields: ReduxFormField[] = [
  {
    name: "code",
    label: "Create Single Coupon",
    component: InputRedux,
    validate:[required,length5],
    cellProps: { md: 12 },
  },
];
