import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { productActions } from "redux/slices/product/productSlice";

const url = `/product/analytics`;

const ProductAnalyticsService = {
  getMostSellingProducts: async (dispatch?: AppDispatch, all = false) => {
    dispatch?.(productActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/most-selling${all ? "?all=true" : ""}`)
    );

    if (success) {
      const { mostSelling } = success.data.data;
      dispatch?.(productActions.setMSProducts({ products: mostSelling }));
    }

    dispatch?.(productActions.setLoading(false));

    return [success, error];
  },
  getBestSellingProductsLast30Days: async (dispatch?: AppDispatch) => {
    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/best-selling`)
    );

    if (success) {
      const { mostSelling } = success.data.data;
      dispatch?.(productActions.setBSProducts30Days({ products: mostSelling }));
    }

    return [success, error];
  },
};

export default ProductAnalyticsService;
