import { Container } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import RoleService from "services/role.service";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CircleLoader from "components/atoms/CircleLoader";
import { useEffect } from "react";
import AddPopularSearchesForm from "./AddPopularSearchesForm";
import UpdatePopularSearchesForm from "./UpdatePopularSearchesForm";
import SearchService from "services/search.service";

export default function AddPopularSearches() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    if (id) SearchService.updateSearch(`${id}`, values, dispatch, navigate);
    else SearchService.createSearch(values, dispatch, navigate);
  };

  return (
    <Container maxWidth="lg">
      <GoBack path="/popular-searches" title="List" />
      <Banner heading={`${!id ? "Add New" : "Update"} Search`}></Banner>
      <BasicCard>
        {loading && <CircleLoader />}
        <AddPopularSearchesForm onSubmit={handleSubmit} />
        {id && <UpdatePopularSearchesForm id={id} />}
      </BasicCard>
    </Container>
  );
}
