import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ProductsService from "services/products.service";
import { productActions } from "redux/slices/product/productSlice";
import SearchSelectRedux from "components/molecules/SearchSelectRedux";
let timeoutId: NodeJS.Timeout | null = null;

export default function SelectProduct(props: any) {
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const productOptions = useAppSelector(
    (state) => state.product.productOptions
  );

  const defaultOptions = useAppSelector(
    (state) => state.form?.["AddDealForm"]?.values?.products
  );

  useEffect(() => {
    ProductsService.getProductOptions({ pageSize: 50 }, dispatch);

    return () => {
      dispatch(productActions.clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void
  ) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    setValue(inputValue);
    timeoutId = setTimeout(async () => {
      let options: any[] = [];
      setPage(1);
      const [success] = await ProductsService.getProductOptions(
        { Name: inputValue, pageSize: 50, page: 1 },
        dispatch
      );
      if (success) {
        if (success.data.data.products.length < 50) setPage(0);
        success.data.data.products.forEach(({ _id, name }: any) =>
          options.push({ value: _id, label: name })
        );
        callback(options);
      }
    }, 1000);
  };

  const handleLoadMore = async () => {
    if (page === 0) return;
    setPage(page + 1);
    let options: any[] = [];
    const [success] = await ProductsService.getProductOptions(
      { Name: value, pageSize: 50, page },
      dispatch
    );
    if (success) {
      if (success.data.data.products.length < 50) setPage(0);
      success.data.data.products.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      dispatch(
        productActions.setProductOptions({
          options: [...productOptions, ...options],
        })
      );
    }
  };

  return (
    <SearchSelectRedux
      {...props}
      defaultValue={defaultOptions}
      loadOptions={loadOptions}
      options={[...productOptions]}
      onMenuScrollToBottom={handleLoadMore}
    />
  );
}
