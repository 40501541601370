import Button from "components/atoms/Button";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { BsPlus, BsTrashFill } from "react-icons/bs";
import Input from "components/atoms/Input";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ProductsService from "services/products.service";
import { useEffect, useState } from "react";

export default function ProductsList() {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.product.products);
  const loading = useAppSelector((state) => state.product.loading);
  const filter = useAppSelector((state) => state.product.filter);
  const refreshLoader = useAppSelector((state) => state.product.refreshLoader);
  const refresh = useAppSelector((state) => state.product.refresh);
  const [stockList, setStockList] = useState<any>([]);

  useEffect(() => {
    ProductsService.getProducts(filter, dispatch);
  }, [filter, refresh, dispatch]);

  const [stock, setStock] = useState(
    products.map(() => ({ add: "", remove: "" }))
  );

  useEffect(() => {
    if (products.length > 0) setStockList(products.map((p: any) => p.stock));
  }, [products]);

  const handleChange = (event: any, index: any) => {
    const { value, name } = event.target;

    setStock((prevStock) => {
      const updatedStock = [...prevStock];
      updatedStock[index] = {
        ...updatedStock[index],
        [name]: value,
      };
      return updatedStock;
    });
  };

  const updateStock = (id: any, stock: any, operation: any, index: number) => {
    let data = { productId: id, stock: stock, operation: operation };
    // @ts-ignore
    ProductsService.updateStock(data, dispatch);

    switch (operation) {
      case "plus":
        setStockList(
          stockList.map((st: any, ind: any) =>
            ind === index ? Number(st) + Number(stock) : st
          )
        );
        break;

      case "minus":
        setStockList(
          stockList.map((st: any, ind: any) => {
            if (ind === index) {
              const actualStock = Number(st) - Number(stock);
              return actualStock >= 0 ? actualStock : 0;
            } else return st;
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        sx={{
          minWidth: "100%",
          borderSpacing: "0 10px",
          borderBottomWidth: "0px",
          borderCollapse: "separate",
        }}
      >
        <TableLoadingWrapper
          container
          coloumns={4}
          loading={loading}
          length={refreshLoader ? 0 : products.length}
          message="No products available"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>SKU</StyledTableCell>
              <StyledTableCell>Stock Type</StyledTableCell>
              <StyledTableCell>Stock Amount</StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Add Stock
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "center" }}>
                Remove Stock
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.length > 0 &&
              stockList.length > 0 &&
              products.map((product: any, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{product.productId}</StyledTableCell>
                  <StyledTableCell>{product.name}</StyledTableCell>
                  <StyledTableCell>{product.sku}</StyledTableCell>
                  <StyledTableCell>{product.stockType}</StyledTableCell>
                  <StyledTableCell>{stockList[index]}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Stack direction="row" spacing={2}>
                      <Input
                        onChange={(event) => handleChange(event, index)}
                        value={stock[index]?.add}
                        name="add"
                        label=""
                      />
                      <Button
                        onClick={() =>
                          updateStock(
                            product._id,
                            stock[index]?.add,
                            "plus",
                            index
                          )
                        }
                        variant="contained"
                        color="primary"
                        disableElevation
                        sx={{ minWidth: "auto" }}
                      >
                        <BsPlus />
                      </Button>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" spacing={2}>
                      <Input
                        onChange={(event) => handleChange(event, index)}
                        value={stock[index]?.remove}
                        name="remove"
                        label=""
                      />
                      <Button
                        onClick={() =>
                          updateStock(
                            product._id,
                            stock[index]?.remove,
                            "minus",
                            index
                          )
                        }
                        variant="contained"
                        color="secondary"
                        disableElevation
                        sx={{ minWidth: "auto" }}
                      >
                        <BsTrashFill   />
                      </Button>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </TableLoadingWrapper>
      </Table>
    </TableContainer>
  );
}
