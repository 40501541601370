import { orderActions } from "../redux/slices/order/orderSlice";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { formLoaderActions } from "redux/slices/formLoader";
import { loaderActions } from "redux/slices/loader";
import { modalActions } from "redux/slices/modal";

const url = `/orders`;

const OrderService = {
  getAllOrders: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(orderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );

    if (success) {
      const { allOrders, count } = success.data.data;
      dispatch?.(orderActions.setOrders({ allOrders: allOrders, count }));
    }

    dispatch?.(orderActions.setLoading(false));

    return [success, error];
  },
  getActiveOrders: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(orderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/active`, { filters })
    );

    if (success) {
      const { activeOrders, count } = success.data.data;
      dispatch?.(
        orderActions.setActiveOrders({ activeOrders: activeOrders, count })
      );
    }

    dispatch?.(orderActions.setLoading(false));

    return [success, error];
  },
  getTodayOrders: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(orderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/today`, { filters })
    );

    if (success) {
      const { todayOrders, count } = success.data.data;
      dispatch?.(
        orderActions.setTodayOrders({ todayOrders: todayOrders, count })
      );
    }

    dispatch?.(orderActions.setLoading(false));

    return [success, error];
  },
  getSingleOrderDetail: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}/details`)
    );

    if (success) {
      const { singleOrderDetails } = success.data.data;
      dispatch?.(orderActions.setorderDetails(singleOrderDetails));
    }

    dispatch?.(loaderActions.setLoading(false));

    return [success, error];
  },
  changeOrderStatus: async (id: any, data: any, dispatch?: AppDispatch) => {
    if (data.adminMessage) {
      dispatch?.(formLoaderActions.setLoading2(true));
    } else dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    if (data?.pageSize) delete data.pageSize;

    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );

    if (success) {
      const orderStatus = success.data.data;
      dispatch?.(orderActions.setorderStatus(orderStatus));
      dispatch?.(modalActions.closeModal());
      // dispatch?.(orderActions.setFilter({}));
      // window.location.reload();
    }

    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(formLoaderActions.setLoading2(false));
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  changePaymentStatus: async (id: any, data: any, dispatch?: AppDispatch) => {
    if (data.adminMessage) {
      dispatch?.(formLoaderActions.setLoading2(true));
    } else dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/payment-status/${id}`, data)
    );

    if (success) {
      const [success]: any = await PromiseAble.asPromise(
        http.get(`${url}/${id}/details`)
      );

      if (success) {
        const { singleOrderDetails } = success.data.data;
        dispatch?.(orderActions.setorderDetails(singleOrderDetails));
        window.location.reload();
      }
    }

    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(formLoaderActions.setLoading2(false));
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  assignShopper: async (id: any, shopper: any, dispatch?: AppDispatch) => {
    let data = { orderId: id, shopperId: shopper };
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/assign`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(orderActions.setFilter({}));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  getRejectedOrders: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(orderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/rejected`, { filters })
    );

    if (success) {
      const { rejectedOrders, count } = success.data.data;
      dispatch?.(
        orderActions.setRejectedOrders({
          rejectedOrders: rejectedOrders,
          count,
        })
      );
    }

    dispatch?.(orderActions.setLoading(false));

    return [success, error];
  },
  getAccountWithMostOrders: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(orderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/account-with-most-orders`, { filters })
    );

    if (success) {
      const { accountWithMostOrders, count } = success.data.data;
      if (filters?.all) {
        dispatch?.(
          modalActions.updateData({
            table_data: accountWithMostOrders.map((user: any) => ({
              "First Name": user.firstName,
              "Last Name": user.lastName,
              Phone: user.phone,
              Email: user?.email ?? "",
              "Order Count": user.ordersCount,
              "Total Price": user.ordersTotal,
            })),
          })
        );
      } else {
        dispatch?.(
          orderActions.setMostOrders({
            mostOrders: accountWithMostOrders,
            count,
          })
        );
      }
    }

    dispatch?.(orderActions.setLoading(false));

    return [success, error];
  },

  assignDriver: async (id: any, driver: any, dispatch?: AppDispatch) => {
    let data = { orderId: id, driverId: driver };
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/assign-driver`, data)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(orderActions.setFilter({}));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
};

export default OrderService;
