import { useEffect } from "react";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CategoriesService from "services/categories.service";

export default function SelectCategories(props: any) {
  const dispatch = useAppDispatch();
  const categoriesOptions = useAppSelector(
    (state) => state.category.categoriesOptions
  );

  useEffect(() => {
    CategoriesService.getCategories({}, dispatch);
  }, [dispatch]);

  return <SelectRedux {...props} options={categoriesOptions} />;
}
