import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CampaignService from "services/campaigns.service";
import { campaignActions } from "redux/slices/campaign/campaignSlice";

export default function AddCampaignForm({ id }: any) {
  const form = "AddCampaignForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const campaign = useAppSelector((state) => state.campaign.campaign);

  useEffect(() => {
    CampaignService.getCampaignbyID(`${id}`, dispatch);
    return () => {
      dispatch(campaignActions.setCampaign(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!campaign || campaign?.data === "Not Found") return;

    const {
      startDate,
      endDate,
      type,
      usageType,
      parentCategory,
      minimumCartTotal,
      name,
      minimumPrice,
      isActive,
      discount,
    } = campaign;
    let { value, type: discountType } = discount;

    // const { type,value }= campaign.discount

   dispatch(
     change(form, "date", {
       error: ["", ""],
       date: [startDate, endDate],
     })
   );
    dispatch(change(form, "minimumCartTotal", minimumCartTotal));
    dispatch(change(form, "isActive", isActive));
    dispatch(change(form, "name", name));
    dispatch(change(form, "parentCategory", parentCategory));
    dispatch(change(form, "usageType", usageType));
    dispatch(change(form, "minimumPrice", minimumPrice));
    dispatch(change(form, "minimumPrice", minimumPrice));
    dispatch(change(form, "type", type));
    dispatch(change(form, "value", value));
    dispatch(change(form, "discountType", discountType));
  }, [campaign, navigate, dispatch]);

  return null;
}
