import CheckboxRedux from "components/molecules/CheckboxRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectRedux from "components/molecules/SelectRedux";
import SelectCategories from "components/organisms/SelectCategories";
import {
  required,
  phoneSimple,
  email,
  number,
  dateRange,
} from "utils/validate.util";

export { default } from "./AddCampaignForm";

export const fields: ReduxFormField[] = [
  {
    label: "Is Active",
    name: "isActive",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "date",
    label: "Date",
    validate: [dateRange],
    component: DateRangePickerRedux,
    cellProps: { md: 12 },
    DateRangePickerProps: {
      onChange: () => {},
      minDate: new Date(),
      InputFieldProps: {},
      value: { date: ["", ""], error: ["", ""] },
    },
  },
  {
    label: "Name",
    name: "name",
    component: InputRedux,
    validate: [required],
    cellProps: { md: 12 },
  },

  {
    label: "Campaign Usage Type",
    name: "usageType",
    component: SelectRedux,
    validate: [required],

    SelectProps: {
      options: [
        { value: "COMMON_CODE_SINGLE_USE", label: "Common Code Single Use" },
        { value: "UNIQUE_CODE", label: "Unique Code" },
      ],
    },
    cellProps: { md: 4 },
  },
  // {
  //   label: "Parent Category",
  //   name: "parentCategory",
  //   validate: [required],
  //   component: SelectCategories,
  //   cellProps: { md: 4 },
  // },

  // {
  //   label: "Campaign Type",
  //   name: "type",
  //   validate: [required],
  //   component: SelectRedux,
  //   SelectProps: {
  //     options: [
  //       { value: "CATEGORY_COMPAIGN", label: "Category Campaign" },
  //       { value: "CARGO_COMPAIGN", label: "Cargo Campaign" },
  //       { value: "CART_COMPAIGN", label: "Cart Campaign" },
  //     ],
  //   },
  //   cellProps: { md: 4 },
  // },

  // {
  //   label: "Category Total Minimum Price",
  //   name: "minimumPrice",
  //   component: InputRedux,
  //   validate: [required, number],

  //   cellProps: { md: 3 },
  // },
  {
    label: "Minimum Cart Total",
    name: "minimumCartTotal",
    component: InputRedux,
    validate: [required, number],

    cellProps: { md: 3 },
  },
  {
    label: "Campaign Discount Type",
    name: "discountType",
    validate: [required],

    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "PERCENTAGE", label: "Percentage" },
        { value: "AMOUNT", label: "Amount" },
      ],
    },
    cellProps: { md: 3 },
  },
  {
    label: "Discount",
    name: "value",
    component: InputRedux,
    validate: [required, number],

    cellProps: { md: 3 },
  },
];
