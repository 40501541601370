import { required, number } from "utils/validate.util";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import ReduxFieldArrayTranslation from "components/organisms/ReduxFieldArrayTranslation";
import SelectProduct from "components/organisms/SelectProduct";

export { default } from "./AddDealForm";

export const fields: ReduxFormField[] = [
  {
    label: "Is Active",
    name: "isActive",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    label: "Display Order",
    name: "displayOrder",
    component: InputRedux,
    validate: [required, number],
    cellProps: { md: 6 },
  },
  {
    name: "translations",
    label: "Translations",
    reduxFormComponent: "FieldArray",
    component: ReduxFieldArrayTranslation,
    fieldsArray: [
      {
        label: "Name",
        name: "translation",
        component: InputRedux,
        cellProps: { md: 12 },
        validate: [required],
      },
    ],
  },
  {
    label: "Select Products",
    name: "products",
    cellProps: { md: 6 },
    component: SelectProduct,
  },
  {
    label: "Product's Display Order",
    name: "productDisplayOrder",
    component: InputRedux,
    validate: [number],
    cellProps: { md: 6 },
  },
];
