import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Checkbox,
} from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import Button from "components/atoms/Button";
import { Link } from "react-router-dom";
import DateService from "utils/date.util";
import OrderStatus from "components/templates/OrderStatus";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { orderActions } from "redux/slices/order/orderSlice";
import { useEffect } from "react";
import { generatePrintableOrders } from "utils/orders.print";

export default function OrdersList({
  orders = [],
  loading,
  refreshLoader,
}: any) {
  const dispatch = useAppDispatch();
  const selectedOrders = useAppSelector(
    (state: any) => state.order.selectedOrders
  );

  const handleCheckboxChange = (order: any) =>
    dispatch(
      selectedOrders.some(({ orderId }: any) => orderId === order.orderId)
        ? orderActions.deselectOrder(order.orderId)
        : orderActions.selectOrder(order)
    );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(
      event.target.checked
        ? orderActions.selectAllOrders(orders)
        : orderActions.deselectAllOrders()
    );

  useEffect(() => {
    // Clear selected orders when the component is unmounted (navigating away)
    return () => {
      dispatch(orderActions.clearSelectedOrders());
    };
  }, [dispatch]);
  

  if (orders?.length <= 0) return <></>;

  return (
    <div>
      {selectedOrders.length > 0 && (
        <Button
          sx={{ position: "absolute", right: "20px", top: "20px" }}
          color="secondary"
          variant="outlined"
          onClick={() => {
            const printWindow: any = window.open(
              "",
              "_blank",
              "width=800,height=600"
            );
            printWindow.document.open();
            printWindow.document.write(generatePrintableOrders(selectedOrders));
            printWindow.document.close();
          }}
        >
          Print orders
        </Button>
      )}
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={9}
            loading={loading}
            message="No order available"
            length={refreshLoader ? 0 : orders?.length ?? 1}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Checkbox
                    checked={selectedOrders.length === orders.length}
                    indeterminate={
                      selectedOrders.length > 0 &&
                      selectedOrders.length < orders.length
                    }
                    onChange={handleSelectAll}
                    inputProps={{ "aria-label": "select all orders" }}
                  />
                </StyledTableCell>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Customer</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Payment</StyledTableCell>
                <StyledTableCell>Source</StyledTableCell>
                <StyledTableCell>Schedule</StyledTableCell>
                <StyledTableCell>Mode</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order: any, i: number) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>
                    <Checkbox
                      checked={selectedOrders.some(
                        (selectedOrder: any) =>
                          selectedOrder.orderId === order.orderId
                      )}
                      onChange={() => handleCheckboxChange(order)}
                      inputProps={{
                        "aria-label": `select order ${order.orderId}`,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{order.orderId}</StyledTableCell>
                  <StyledTableCell>
                    {DateService.getShortDateString(order.date)}
                    <br />
                    {DateService.getTimeString(order.date)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {order.firstName} {order.lastName} <br />
                    {order.phone}
                  </StyledTableCell>
                  <StyledTableCell>
                    <OrderStatus id={order.id} status={order.orderStatus} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <b>{order.total}</b>
                    <br />
                    <span
                      style={{
                        background:
                          order?.paymentStatus === "paid"
                            ? "#1f691e"
                            : order?.paymentStatus === "unpaid"
                            ? "#000"
                            : order?.paymentStatus === "pending"
                            ? "#f10d72"
                            : "blue",
                        padding: "2px 8px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {order?.paymentStatus.toUpperCase()}
                    </span>{" "}
                    <span
                      style={{
                        background: order?.isOnStorePayment
                          ? "#f10d72"
                          : "#1f691e",
                        padding: "2px 8px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {order?.isOnStorePayment ? "STORE" : "ONLINE"}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {order?.source === 0
                      ? "App"
                      : order?.source === 1
                      ? "Web"
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {order.scheduling?.deliveryDate ?? "N/A"} <br />
                    {order.scheduling?.slot ?? ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    <span
                      style={{
                        background: order?.isPickupFromStore
                          ? "#f10d72"
                          : "#1f691e",
                        padding: "2px 8px",
                        borderRadius: "50px",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {order?.isPickupFromStore ? "Store" : "Delivery"}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Link
                      to={`/orders/${order.id}`}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Button
                        variant="outlined"
                        disableElevation
                        sx={{ minWidth: "auto" }}
                      >
                        <ReadMoreIcon
                          onClick={(event) => {
                            if (
                              event.button === 1 ||
                              (event.button === 0 && event.ctrlKey)
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Button>
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
}
