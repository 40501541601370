import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { searchState } from ".";

const initialState: searchState = {
  searches: [],
  userSearches: [],
  loading: true,
  singleSearch: null,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearches: (state, action) => {
      state.searches = action.payload;
    },
    setUserSearches: (state, action) => {
      state.userSearches = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSingleSearch: (state, action) => {
      state.singleSearch = action.payload;
    },
  },
});

const searchReducer = searchSlice.reducer;

export const searchActions = searchSlice.actions;
export default searchReducer;
