import { Grid } from "@mui/material";
import BasicCard from "components/templates/BasicCard";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import CouponService from "services/coupon.service";
import AddCouponForm from "./AddCouponForm";
import GenerateCouponForm from "./GenerateCouponForm";

export default function AddCoupon() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const handleSubmit = (values: any) => {
    let data = { ...values };
    data.code = data.code.toLowerCase();

    CouponService.createCoupon(data, id, dispatch, navigate);
  };

  const GeneratehandleSubmit = (values: any) => {
    CouponService.generateCoupons(values, id, dispatch, navigate);
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item md={6}>
          <BasicCard>
            <AddCouponForm onSubmit={handleSubmit} />
          </BasicCard>
        </Grid>
        <Grid item md={6}>
          <BasicCard>
            <GenerateCouponForm onSubmit={GeneratehandleSubmit} />
          </BasicCard>
        </Grid>
      </Grid>
    </>
  );
}
