import { useEffect } from "react";
import { TableContainer, Chip } from "@mui/material";
import SearchService from "services/search.service";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const UserSearchesList = () => {
  const dispatch = useAppDispatch();
  const userSearches = useAppSelector((state) => state.search.userSearches);

  useEffect(() => {
    SearchService.getUserSearches(dispatch);
  }, [dispatch]);
  return (
    <div>
      {userSearches.map((data: any, i: number) => (
        <TableContainer>
          <h3>{data.currentMonth}</h3>
          {data.searchTerms.map((t: any) => (
            <Chip label={t} sx={{ marginRight: "6px", marginBottom: "8px" }} />
          ))}
        </TableContainer>
      ))}
    </div>
  );
};

export default UserSearchesList;
