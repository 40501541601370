import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { number } from "utils/validate.util";
import { pageSize } from "utils/normalize.util";
import SelectCategories from "components/organisms/SelectCategories";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectSubCategoriesByCategoryId from "components/organisms/SelectSubCategoriesByCategoryId";

export { default } from "./ProductFilters";

export const fields: ReduxFormField[] = [
  {
    label: "Critical Stock",
    name: "criticalStock",
    component: CheckboxRedux,
    cellProps: { md: 4 },
  },
  {
    name: "Id",
    label: "Id",
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "Sku",
    label: "SKU",
    component: InputRedux,
    cellProps: { md: 4 },
    validate: [number],
  },
  {
    name: "Name",
    label: "Name",
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    label: "Category",
    name: "mainCategory",
    component: SelectCategories,
    cellProps: { md: 4 },
  },
  {
    label: "Sub-Category",
    name: "Category",
    component: SelectSubCategoriesByCategoryId,
    cellProps: { md: 4 },
  },
  {
    name: "IsPublished",
    label: "Is Published",
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
    },
    cellProps: { md: 4 },
  },
  {
    name: "isOutOfBerlinAllowed",
    label: "Out of Berlin",
    component: SelectRedux,
    SelectProps: {
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
    },
    cellProps: { md: 4 },
  },
  {
    label: "Page Size",
    name: "pageSize",
    component: InputRedux,
    cellProps: { md: 4 },
    normalize: pageSize,
  },
];
