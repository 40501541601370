import { Container } from "@mui/material";
import CircleLoader from "components/atoms/CircleLoader";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AddDealForm from "./AddDealForm";
import UpdateDealForm from "./UpdateDealForm";
import DealsService from "services/deals.service";

export default function AddDeal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let payload = { ...values };

    payload.products = payload.productList.map((p: any) => {
      return { productId: p.product.value, displayOrder: p.displayOrder };
    });

    delete payload.productList;
    delete payload.productDisplayOrder;

    if (id) DealsService.updateDeal(`${id}`, payload, dispatch, navigate);
    else DealsService.addDeal(payload, dispatch, navigate);
  };

  return (
    <Container maxWidth="lg">
      <GoBack path="/deals-management" title="Deals" />
      <Banner heading={`${id ? "Update" : "Add New"} Deals`}></Banner>
      <div style={{ position: "relative" }}>
        {loading && <CircleLoader />}
        <BasicCard>
          <AddDealForm onSubmit={handleSubmit} />
          {id && <UpdateDealForm id={id} />}
        </BasicCard>
      </div>
    </Container>
  );
}
