import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import { required } from "utils/validate.util";

export { default } from "./GenerateCouponForm";

export const fields: ReduxFormField[] = [
  {
    name: "count",
    label: "Generate Coupons",
    component: InputRedux,
    cellProps: { md: 12 },
    validate: [required],
  },
];
