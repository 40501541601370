import { modalActions } from "redux/slices/modal";
import { formLoaderActions } from "redux/slices/formLoader";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";

const url = "/auth";
const base = process.env.REACT_APP_USER_URL;

const AuthService = {
  login: async (data: any, dispatch?: AppDispatch) => {
    dispatch?.(authActions.setLoading(true));

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/login`, data)
    );

    if (success) {
      const { token } = success.data.data;

      localStorage.setItem("token", `Bearer ${token}`);
      dispatch?.(authActions.setUser(data));
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  logout: () => {
    const dispatch = getAppDispatch();

    localStorage.removeItem("token");

    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
  },
  getUserbyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`user/${id}`)
    );

    if (success) {
      const { user } = success.data.data;
      dispatch?.(authActions.setSingleUser(user));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteUser: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`user/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(authActions.deleteUser(id));
    }
    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
  createUser: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      data.role === "User"
        ? http.post(`/auth/register`, data, { baseURL: base })
        : http.post(`${url}/register`, data)
    );
    if (success) {
      navigate?.("/account-management");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getUsers: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(authActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`user/filter`, { filters })
    );

    if (success) {
      const { users, count } = success.data.data;
      dispatch?.(authActions.setUsers({ users: users, count }));
    }

    dispatch?.(authActions.setLoading(false));

    return [success, error];
  },
  updateUser: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`user/${id}`, data)
    );
    if (success) {
      const user = success.data.data;
      dispatch?.(authActions.updateUser({ id, user }));
      navigate?.("/account-management");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};
export default AuthService;
