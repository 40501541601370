import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";

export { default } from "./DeliveryFeeCard";

export const fields: ReduxFormField[] = [
  {
    label: "Page Size",
    name: "page_size",
    component: InputRedux,
    cellProps: { md: 12 },
  },
];
