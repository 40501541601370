import Button from "components/atoms/Button";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { BsTrashFill } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { MODAL } from "redux/slices/modal";
import { modalActions } from "redux/slices/modal";
import { Link } from "react-router-dom";
import DealsService from "services/deals.service";

export default function DealsList() {
  const dispatch = useAppDispatch();
  const deals = useAppSelector((state) => state.deal.deals);
  const loading = useAppSelector((state) => state.deal.loading);
  const refreshLoader = useAppSelector((state) => state.deal.refreshLoader);
  const refresh = useAppSelector((state) => state.deal.refresh);

  useEffect(() => {
    DealsService.getAll(dispatch);
  }, [refresh, dispatch]);

  return (
    <div>
      <TableContainer>
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            container
            coloumns={4}
            loading={loading}
            message="No categories available"
            length={refreshLoader ? 0 : deals.length}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Display Order</StyledTableCell>
                <StyledTableCell>Published</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deals?.map((d: any, i: number) => (
                <StyledTableRow key={i}>
                  <StyledTableCell>{d?.name}</StyledTableCell>
                  <StyledTableCell>{d.displayOrder}</StyledTableCell>
                  <StyledTableCell>
                    {d?.isActive === true ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Stack direction="row" spacing={2} justifyContent="right">
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() =>
                          dispatch(
                            modalActions.openModal({
                              width: "500px",
                              type: MODAL.CONFIRMATION_FORM,
                              data: {
                                id: d._id,
                                type: MODAL.DELETE_DEAL,
                                heading: "Delete Offer",
                                message:
                                  "Do you really want to delete this Offer?",
                              },
                            })
                          )
                        }
                        sx={{ minWidth: "auto" }}
                      >
                        <BsTrashFill />
                      </Button>
                      <Link
                        to={`/update-deal/${d._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          sx={{ minWidth: "auto" }}
                          onClick={(event) => {
                            if (
                              event.button === 1 ||
                              (event.button === 0 && event.ctrlKey)
                            ) {
                              event.preventDefault();
                            }
                          }}
                        >
                          <AiOutlineEdit />
                        </Button>
                      </Link>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </TableContainer>
    </div>
  );
}
