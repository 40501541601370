import CheckboxRedux from "components/molecules/CheckboxRedux";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import SelectRedux from "components/molecules/SelectRedux";
import { pageSize } from "utils/normalize.util";
import { dateRangeFilter } from "utils/validate.util";
export { default } from "./OrdersFilters";

export const fields = (isTodayOrder: boolean = false) => {
  const fields: ReduxFormField[] = [
    {
      name: "isPickupFromStore",
      label: "Pickup From Store",
      component: CheckboxRedux,
      cellProps: { md: 4 },
    },
    {
      name: "isOnStorePayment",
      label: "On Store Payment",
      component: CheckboxRedux,
      cellProps: { md: 8 },
    },
    {
      name: "Id",
      label: "Id",
      component: InputRedux,
      cellProps: { md: 4 },
    },

    {
      name: "FirstName",
      label: "First Name",
      component: InputRedux,
      cellProps: { md: 4 },
    },
    {
      name: "LastName",
      label: "Last Name",
      component: InputRedux,
      cellProps: { md: 4 },
    },
    {
      name: "Email",
      label: "Email",
      component: InputRedux,
      cellProps: { md: 4 },
    },
    {
      name: "Phone",
      label: "Phone",
      component: InputRedux,
      cellProps: { md: 4 },
    },
    {
      name: "Status",
      label: "Order Status",
      component: SelectRedux,
      SelectProps: {
        options: [
          { value: "status_ready_to_be_packed", label: "Ready To Be Packed " },
          {
            value: "status_currently_being_picked",
            label: "Currently Being Picked ",
          },
          { value: "status_partially_ready", label: "Partially Ready " },
          { value: "status_order_ready", label: "Order Ready " },
          { value: "status_out_for_delivery", label: "Out For Delivery" },
          { value: "status_delivered", label: "Completed" },
          { value: "status_delivery_failed", label: "Cancelled " },
        ],
      },
      cellProps: { md: 4 },
    },
    {
      name: "source",
      label: "Order Source",
      component: SelectRedux,
      SelectProps: {
        options: [
          { value: "0", label: "App" },
          {
            value: "1",
            label: "Web",
          },
        ],
      },
      cellProps: { md: 4 },
    },

    {
      label: "Minimum Cart",
      name: "minimumCart",
      component: InputRedux,
      cellProps: { md: 4 },
    },
    {
      label: "Maximum Cart",
      name: "maximumCart",
      component: InputRedux,
      cellProps: { md: 4 },
    },

    {
      name: "date",
      label: "Date",
      validate: [dateRangeFilter],
      component: DateRangePickerRedux,
      cellProps: { md: 8 },
      DateRangePickerProps: {
        onChange: () => {},
        InputFieldProps: {},
        value: { date: ["", ""], error: ["", ""] },
      },
    },
    {
      label: "Page Size",
      name: "pageSize",
      normalize: pageSize,
      component: InputRedux,
      cellProps: { md: 4 },
    },
  ];

  if (!isTodayOrder)
    fields.splice(7, 0, {
      name: "deliveryArea",
      label: "Delivery Area",
      component: SelectRedux,
      SelectProps: {
        options: [
          { value: "berlin", label: "Berlin" },
          {
            value: "outOfBerlin",
            label: "Out Of Berlin",
          },
        ],
      },
      cellProps: { md: 4 },
    });

  return fields;
};
