import { ZipCodeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: ZipCodeState = {
  campaigns: [],
  loading: true,
  campaign: null,
  count: 0,
  tab: 0,
  show: "list",
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setCampaigns: (
      state,
      action: PayloadAction<{ campaigns: any[]; count: number }>
    ) => {
      const { campaigns, count } = action.payload;
      state.campaigns = campaigns;
      state.count = count;
      state.refreshLoader = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    filtercampaign: (state, action) => {
      const id = action.payload;
      state.campaigns = state.campaigns.filter(({ _id }) => _id !== id);
    },
    updateCampaign: (state, action) => {
      const { id, campaign } = action.payload;
      state.campaigns.every(({ _id }, i) => {
        if (id === _id) {
          state.campaigns[i] = campaign;
          return false;
        }
        return true;
      });
    },
  },
});

const campaignReducer = campaignSlice.reducer;

export const campaignActions = campaignSlice.actions;
export default campaignReducer;
