import { AuthState } from ".";
import LocalStorage from "utils/localstorage.util";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const user = LocalStorage.getItem("user");
const initialState: AuthState = {
  user,
  users: [],
  userOptions: [],
  drivers: [],
  loading: false,
  singleUser: null,
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {},
    setUser: (state, action) => {
      state.user = action.payload;
      LocalStorage.setItem("user", state.user);
    },

    setSingleUser: (state, action) => {
      state.singleUser = action.payload;
    },
    setUsers: (
      state,
      action: PayloadAction<{ users: any[]; count: number }>
    ) => {
      const { users, count } = action.payload;
      state.users = users;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      let allDrivers: SelectOption[] = [];
      users.forEach(
        ({ _id, firstName, lastName, role }: any) =>
          role === "Shopper" &&
          options.push({ value: _id, label: `${firstName} ${lastName}` })
      );
      users.forEach(
        ({ _id, firstName, lastName, role }: any) =>
          role === "Driver" &&
          allDrivers.push({ value: _id, label: `${firstName} ${lastName}` })
      );
      state.users = users;
      state.userOptions = options;
      state.drivers = allDrivers;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    deleteUser: (state, action) => {
      const id = action.payload;
      state.users = state.users.filter((user) => {
        return user._id !== id;
      });
    },
    updateUser: (state, action) => {
      const { id, user } = action.payload;
      state.users.every(({ _id }, i) => {
        if (id === _id) {
          state.users[i] = user;
          return false;
        }
        return true;
      });
    },
  },
});

const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
export default authReducer;
