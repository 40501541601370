import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { change, reduxForm } from "redux-form";
import { fields } from ".";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableCell, StyledTableRow } from "components/templates/Tables";
import { BsTrashFill } from "react-icons/bs";

const AddDealForm = ({ form, handleSubmit }: any) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(
    (state) => state.form?.[form]?.values?.products
  );
  const productDisplayOrder = useAppSelector(
    (state) => state.form?.[form]?.values?.productDisplayOrder
  );
  const productList = useAppSelector(
    (state) => state.form?.[form]?.values?.productList ?? []
  );

  function removeDuplicates(array: any) {
    return array.reduce((accumulator: any, current: any) => {
      if (
        !accumulator.some(
          (item: any) => item.product.value === current.product.value
        )
      ) {
        accumulator.push(current);
      }
      return accumulator.sort(
        (a: any, b: any) => a.displayOrder - b.displayOrder
      );
    }, []);
  }

  const removeFromListHandler = (index: number) => {
    dispatch(
      change(
        form,
        "productList",
        removeDuplicates(productList.filter((_: any, i: number) => index !== i))
      )
    );
  };

  const handleProductList = () => {
    if (products && productDisplayOrder) {
      const item = { product: products, displayOrder: productDisplayOrder };

      dispatch(
        change(form, "productList", removeDuplicates([...productList, item]))
      );

      dispatch(change(form, "products", []));
      dispatch(change(form, "productDisplayOrder", null));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields} />

        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: 10, marginBottom: 10, alignItems: "left" }}
          onClick={() => handleProductList()}
        >
          Add Products to List
        </Button>

        <TableContainer>
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              container
              coloumns={3}
              loading={false}
              message="No products available"
              length={productList.length === 0 ? 0 : productList.length}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Product Name</StyledTableCell>
                  <StyledTableCell>Display Order</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList?.map((p: any, i: number) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell>{p?.product.label}</StyledTableCell>
                    <StyledTableCell>{p.displayOrder}</StyledTableCell>
                    <Button onClick={() => removeFromListHandler(i)}>
                      <BsTrashFill />
                    </Button>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
        </TableContainer>

        <br />
        <Button color="primary" variant="contained" type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};

export default reduxForm({
  form: "AddDealForm",
})(AddDealForm);
