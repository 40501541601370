import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import DealsService from "services/deals.service";
import { dealActions } from "redux/slices/deal/dealSlice";

export default function UpdateDealForm({ id }: any) {
  const form = "AddDealForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deal = useAppSelector((state) => state.deal.deal);

  useEffect(() => {
    DealsService.getById(`${id}`, dispatch);
    return () => {
      dispatch(dealActions.setDeal(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!deal) return;

    const { isActive, displayOrder, translations, products } = deal;

    dispatch(change(form, "isActive", isActive));
    dispatch(change(form, "displayOrder", displayOrder));
    dispatch(
      change(
        form,
        "translations",
        translations.map(({ _id, ...rest }: any) => ({ ...rest }))
      )
    );
    dispatch(
      change(
        form,
        "productList",
        products.map((p: any) => {
          return {
            product: {
              value: p.productId?._id,
              label: p.productId?.translations?.[0]?.name,
            },
            displayOrder: p.displayOrder,
          };
        })
      )
    );
  }, [deal, navigate, dispatch]);

  return null;
}
