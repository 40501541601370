import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

interface SubCategoryState {
  subCategoriesOptions: any[];
  selectedSubCategoriesOptions: any[];
  subCategories: any[];
  loading: boolean;
  subCategory: any;
  count: number;
  refresh: number;
  refreshLoader: boolean;
  filter: any;
}

const initialState: SubCategoryState = {
  subCategoriesOptions: [],
  selectedSubCategoriesOptions: [],
  subCategories: [],
  loading: true,
  subCategory: null,
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    clear: (state) => {
      state.subCategories = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategory = action.payload;
    },
    setSubCategories: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      const { data, count } = action.payload;
      state.subCategories = data;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      data.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.subCategories = data;
      state.subCategoriesOptions = options;
    },

    setSelectedSubSubCategories: (
      state,
      action: PayloadAction<{ data: any[] }>
    ) => {
      const { data } = action.payload;

      let options: SelectOption[] = [];
      data.forEach(({ _id, name }: any) => {
        options.push({ value: _id, label: name });
      });
      state.selectedSubCategoriesOptions = options;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    addSubCategory: (state, action) => {
      state.subCategories.push(action.payload);
    },
    filterSubCategory: (state, action) => {
      const id = action.payload;
      state.subCategories = state.subCategories.filter(({ _id }) => _id !== id);
    },
    updateSubCategory: (state, action) => {
      const { id, SubCategory } = action.payload;

      state.subCategories.every(({ _id }, i) => {
        if (id === _id) {
          state.subCategories[i] = SubCategory;
          return false;
        }
        return true;
      });
    },
  },
});

const subCategoryReducer = subCategorySlice.reducer;
export const subCategoryActions = subCategorySlice.actions;
export default subCategoryReducer;
