import { Container, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useNavigate } from "react-router-dom";
import DealsList from "./DealsList";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { MODAL, modalActions } from "redux/slices/modal";

export default function DealsManagement() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const count = useAppSelector((state) => state.deal.count);
  const deals = useAppSelector((state) => state.deal.deals);
  return (
    <Container maxWidth="lg">
      <Banner heading={`Deal Management (${count})`}>
        <Stack direction="row" spacing={2}>
          {deals.length > 0 && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                dispatch(
                  modalActions.openModal({
                    width: "500px",
                    type: MODAL.EXPORT_TABLE_OPTIONS,
                    data: {
                      table_data: deals.map((deal: any) => ({
                        Name: deal.name,
                        "Is Active": deal.isActive,
                        "Display Order": deal.displayOrder,
                      })),
                      file_name: "Deals",
                    },
                  })
                );
              }}
            >
              Export
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/add-deal")}
          >
            + Add New
          </Button>
        </Stack>
      </Banner>

      <br />
      <BasicCard>
        <DealsList />
      </BasicCard>
    </Container>
  );
}
