import { formLoaderActions } from "../redux/slices/formLoader/formLoaderSlice";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { NavigateFunction } from "react-router-dom";
import { dealActions } from "redux/slices/deal/dealSlice";
import { modalActions } from "redux/slices/modal";

const url = "/deal";

const DealsService = {
  getById: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { deal } = success.data.data;
      dispatch?.(dealActions.setDeal(deal));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getAll: async (dispatch?: AppDispatch) => {
    dispatch?.(dealActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { deals } = success.data.data;

      dispatch?.(dealActions.setDeals({ data: deals, count: deals.length }));
    }

    dispatch?.(dealActions.setLoading(false));

    return [success, error];
  },
  addDeal: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const { deal } = success.data.data;
      dispatch?.(dealActions.setDeal(deal));

      navigate?.("/deals-management");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteDeal: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(dealActions.filterDeals(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateDeal: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.patch(`${url}/${id}`, data)
    );
    if (success) {
      const { deal } = success.data.data;
      dispatch?.(dealActions.updateDeal({ id, deal }));
      navigate?.("/deals-management");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default DealsService;
