import { couponActions } from "./../redux/slices/coupon/couponSlice";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { modalActions } from "redux/slices/modal";
import { formLoaderActions } from "redux/slices/formLoader";
import { campaignActions } from "redux/slices/campaign/campaignSlice";

const url = `/compaign`;

const CouponService = {
  getCouponByID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}/coupons/${id}`)
    );

    if (success) {
      const { coupon } = success.data.data;
      dispatch?.(couponActions.setCoupon(coupon));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getCoupons: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(couponActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}/coupons`)
    );

    if (success) {
      const { coupons } = success.data.data;
      dispatch?.(couponActions.setCoupons(coupons));
    }

    dispatch?.(couponActions.setLoading(false));

    return [success, error];
  },
  createCoupon: async (
    data: any,
    compaignID: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/${compaignID}/coupons/custom`, data)
    );

    if (success) {
      dispatch?.(campaignActions.setShow("list"));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  generateCoupons: async (
    data: any,
    compaignID: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/${compaignID}/coupons`, data)
    );

    if (success) {
      dispatch?.(campaignActions.setShow("list"));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteCoupon: async (compaignID: any, id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${compaignID}/coupons/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(couponActions.filterCoupon(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  deleteAllCoupon: async (compaignID: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${compaignID}/coupons`)
    );

    if (success) {
      dispatch?.(couponActions.setCoupons([]));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateCoupon: async (
    compaignID: any,
    id: any,
    data: any,
    dispatch?: AppDispatch
  ) => {
    dispatch?.(modalActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${compaignID}/coupons/${id}`, data)
    );
    if (success) {
      const { coupon } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(couponActions.updateCoupon({ id, coupon }));
    }
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
};

export default CouponService;
