import { Container, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useNavigate } from "react-router-dom";
import PopularSearchesList from "./PopularSearchesList/PopularSearchesList";
import UserSearchesList from "./UserSearchesList";

const PopularSearches = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="lg">
        <Banner heading={`Suggestions`}>
          <Stack direction="row" spacing={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => navigate("/add-search")}
            >
              + Add New
            </Button>
          </Stack>
        </Banner>
        <br />
        <BasicCard>
          <PopularSearchesList />
        </BasicCard>
      </Container>
      <Container maxWidth="lg">
        <Banner heading={`User Searches`}>
        </Banner>
        <br />
        <BasicCard>
          <UserSearchesList />
        </BasicCard>
      </Container>
    </>
  );
};

export default PopularSearches;
