import { Container, Stack } from "@mui/material";
import Button from "components/atoms/Button";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { useNavigate, useParams } from "react-router-dom";
import CoupanManagementList from "./CoupanManagementList";
import CoupanManagementFilter from "./CoupanManagementFilter";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { campaignActions } from "redux/slices/campaign/campaignSlice";
import AddCampaign from "pages/AddCampaign";
import { ArrowBack } from "@mui/icons-material";
import AddCoupon from "pages/AddCoupon";
import { MODAL } from "redux/slices/modal";
import { modalActions } from "redux/slices/modal";
import CircleLoader from "components/atoms/CircleLoader";

const CoupanManagement = () => {
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.formLoader.loading);
  const dispatch = useAppDispatch();
  const show = useAppSelector((state) => state.campaign.show);
  const { id } = useParams();

  useEffect(() => {
    return () => {
      dispatch(campaignActions.setShow("list"));
    };
  }, [dispatch]);
  return (
    <>
      {show !== "list" && (
        <ArrowBack
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(campaignActions.setShow("list"))}
        />
      )}
      {show === "list" && (
        <>
          <Banner>
            <Button
              color="error"
              variant="contained"
              onClick={() =>
                dispatch(
                  modalActions.openModal({
                    width: "500px",
                    type: MODAL.CONFIRMATION_FORM,
                    data: {
                      compaignID: id,
                      type: MODAL.DELETE_AllCOUPON,
                      heading: "Delete Coupons",
                      message: "Do you really want to delete All Coupons?",
                    },
                  })
                )
              }
              sx={{ marginRight: "10px" }}
            >
              DELETE ALL COUPONS
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(campaignActions.setShow("add"))}
            >
              + Add New
            </Button>
          </Banner>
        </>
      )}

      {show === "list" ? (
        <BasicCard>
          <CoupanManagementList />
        </BasicCard>
      ) : (
        <>
          {" "}
          {loading && <CircleLoader />} <AddCoupon />
        </>
      )}
    </>
  );
};

export default CoupanManagement;
