import { Container } from "@mui/material";
import { BiRefresh } from "react-icons/bi";
import Button from "components/atoms/Button";
import Pagination from "components/organisms/Pagination";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import { orderActions } from "redux/slices/order/orderSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import OrderButton from "components/atoms/OrderButton";
import OrdersFilters from "../OrdersFilters";
import TodayOrderList from "./TodaysOrdersList/TodaysOrdersList";

export default function TodayOrders() {
  const dispatch = useAppDispatch();
  const filter = useAppSelector((state) => state.order.filter);
  const count = useAppSelector((state) => state.order.count);
  const refreshLoader = useAppSelector((state) => state.order.refreshLoader);

  const reloadActive = () => {
    dispatch(orderActions.setFilter({}));
  };

  return (
    <Container maxWidth="lg">
      <Banner heading={`Today Orders (${count})`}>
        <Button onClick={reloadActive} color="primary" variant="contained">
          <BiRefresh /> Reload
        </Button>
      </Banner>
      <BasicCard>
        <OrdersFilters isTodayOrder={true} />
      </BasicCard>

      {!refreshLoader && count > 0 && (
        <Pagination
          page={filter.page}
          count={Math.ceil(count / filter.pageSize)}
          onChange={(_e, page) => dispatch(orderActions.setPage(page))}
        />
      )}

      <br />
      <BasicCard>
        <OrderButton />
        <TodayOrderList />
      </BasicCard>
    </Container>
  );
}
