import { FiltersFormProps } from ".";
import { Stack } from "@mui/material";
import Button from "components/atoms/Button";
import { InjectedFormProps, change, reduxForm } from "redux-form";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useEffect } from "react";
import { useAppDispatch } from "redux/hooks";

const FiltersForm = ({
  form,
  myFields,
  handleSubmit,
  onClickReset,
  btn,
  submitBtnText = "Submit",
  showButton = false,
}: FiltersFormProps & InjectedFormProps<{}, FiltersFormProps>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(change(form, "pageSize", 30));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <ReduxFormFields fields={myFields} />
      <br />
      <Stack direction="row" spacing={2}>
        {!btn && (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={showButton}
          >
            {submitBtnText}
          </Button>
        )}
        {onClickReset && (
          <Button color="secondary" variant="contained" onClick={onClickReset}>
            RESET
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default reduxForm<{}, FiltersFormProps>({
  form: "FiltersForm",
})(FiltersForm);
