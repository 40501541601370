import { SelectOption } from "components/atoms/Select";
import { RoleState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: RoleState = {
  roles: [],
  loading: true,
  role: null,
  roleOptions: [],
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setRoles: (
      state,
      action: PayloadAction<{ roles: any[]; count: number }>
    ) => {
      const { roles, count } = action.payload;
      state.roles = roles;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      roles?.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.roles = roles;
      state.roleOptions = options;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    filterRole: (state, action) => {
      const id = action.payload;
      state.roles = state.roles.filter(({ _id }) => _id !== id);
    },
    updateRole: (state, action) => {
      const { id, role } = action.payload;
      state.roles.every(({ _id }, i) => {
        if (id === _id) {
          state.roles[i] = role;
          return false;
        }
        return true;
      });
    },
  },
});

const roleReducer = roleSlice.reducer;

export const roleactions = roleSlice.actions;
export default roleReducer;
