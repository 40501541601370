import { formLoaderActions } from "./../redux/slices/formLoader/formLoaderSlice";
import { modalActions } from "./../redux/slices/modal/modalSlice";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { categoryActions } from "redux/slices/category/categorySlice";
import { NavigateFunction } from "react-router-dom";

const url = "/categories";

const CategoriesService = {
  getCategorybyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { category } = success.data.data;
      dispatch?.(categoryActions.setCategory(category));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getCategories: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(categoryActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );

    if (success) {
      const { data, count } = success.data.data;

      dispatch?.(categoryActions.setCategories({ data: data, count }));
    }

    dispatch?.(categoryActions.setLoading(false));

    return [success, error];
  },
  addCategory: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const category = success.data.data;
      dispatch?.(categoryActions.addCategory(category));

      navigate?.("/category-management");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteCategory: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(categoryActions.filterCategory(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateCategory: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      const { category } = success.data.data;
      dispatch?.(categoryActions.updateCategory({ id, category }));

      navigate?.("/category-management");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default CategoriesService;
