import { ZipCodeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: ZipCodeState = {
  banners: [],
  loading: true,
  banner: null,
  filterBanner: "",
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBanner: (state, action) => {
      state.banner = action.payload;
    },
    setBanners: (
      state,
      action: PayloadAction<{ banners: any[]; count: number }>
    ) => {
      const { banners, count } = action.payload;
      state.banners = banners;
      state.count = count;
      state.refreshLoader = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    filterbanner: (state, action) => {
      const id = action.payload;
      state.banners = state.banners.filter(({ _id }) => _id !== id);
    },
    updateBanner: (state, action) => {
      const { id, banner } = action.payload;
      state.banners.every(({ _id }, i) => {
        if (id === _id) {
          state.banners[i] = banner;
          return false;
        }
        return true;
      });
    },
  },
});

const bannerReducer = bannerSlice.reducer;

export const bannerActions = bannerSlice.actions;
export default bannerReducer;
