import { ZipCodeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: ZipCodeState = {
  zipcodes: [],
  zipCodesRequest: [],
  mostRequestedZipCodes: [],
  loading: true,
  zipcode: null,
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const zipcodeSlice = createSlice({
  name: "zipcode",
  initialState,
  reducers: {
    setZipCode: (state, action) => {
      state.zipcode = action.payload;
    },
    setZipCodes: (
      state,
      action: PayloadAction<{ zipcodes: any[]; count: number }>
    ) => {
      const { count, zipcodes } = action.payload;
      state.count = count;
      state.zipcodes = zipcodes;
      state.refreshLoader = false;
    },
    setZipCodesRequest: (
      state,
      action: PayloadAction<{ requestedZipCodes: any[]; count: number }>
    ) => {
      const { count, requestedZipCodes } = action.payload;
      state.count = count;
      state.zipCodesRequest = requestedZipCodes;
      state.refreshLoader = false;
    },
    setmostRequestZipCodes: (state, action) => {
      state.mostRequestedZipCodes = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },

    filterZipCode: (state, action) => {
      const id = action.payload;
      state.zipcodes = state.zipcodes.filter(({ _id }) => _id !== id);
    },
    updateZipCode: (state, action) => {
      const { id, zipcode } = action.payload;
      state.zipcodes.every(({ _id }, i) => {
        if (id === _id) {
          state.zipcodes[i] = zipcode;
          return false;
        }
        return true;
      });
    },
  },
});

const zipcodeReducer = zipcodeSlice.reducer;

export const zipcodeActions = zipcodeSlice.actions;
export default zipcodeReducer;
