import SelectRedux from "components/molecules/SelectRedux";
import { useAppSelector } from "redux/hooks";

export default function SelectSubCategoriesByCategoryId(props: any) {
  const subCategoriesOptions = useAppSelector(
    (state) => state.subCategory.selectedSubCategoriesOptions
  );

  return <SelectRedux {...props} options={subCategoriesOptions} />;
}
