import { Pagination as BasePagination, PaginationProps } from "@mui/material";

const Pagination = (props: PaginationProps) => {
  return (
    <div
      className="pagination-list"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <BasePagination {...props} />
    </div>
  );
};

export default Pagination;
