import { SelectOption } from "./../../../components/atoms/Select/index";
import { ProductState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: ProductState = {
  product: null,
  products: [],
  msProducts: [],
  bsProducts30Days: [],
  loading: true,
  tab: 0,
  show: "list",
  productOptions: [],
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clear: (state) => {
      state.productOptions = [];
    },
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setShow: (state, action) => {
      state.show = action.payload;
    },
    setProducts: (
      state,
      action: PayloadAction<{ products: any[]; count: number }>
    ) => {
      const { products, count } = action.payload;
      state.products = products;
      state.count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      products.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.products = products;
      state.productOptions = options;
    },
    setMSProducts: (state, action: PayloadAction<{ products: any[] }>) => {
      const { products } = action.payload;
      state.msProducts = products;
      state.refreshLoader = false;
    },
    setBSProducts30Days: (
      state,
      action: PayloadAction<{ products: any[] }>
    ) => {
      const { products } = action.payload;
      state.bsProducts30Days = products;
    },
    setProductOptions: (state, action: PayloadAction<{ options: any[] }>) => {
      const { options } = action.payload;
      state.productOptions = options;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },
    addProduct: (state, action) => {
      const product = action.payload;
      state.products = [...state.products, product];
    },
    deleteProduct: (state, action) => {
      const productId = action.payload;
      state.products = state.products.filter((product) => {
        return product._id !== productId;
      });
    },
    updateProduct: (state, action) => {
      // const { id, product } = action.payload;
      // state.products.every(({ _id }, i) => {
      //   if (id === _id) {
      //     state.products[i] = product;
      //     return false;
      //   }
      //   return true;
      // });
      state.product = action.payload;
    },
  },
});

const productReducer = productSlice.reducer;

export const productActions = productSlice.actions;
export default productReducer;
