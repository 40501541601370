import { faqState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: faqState = {
  faqs: [],
  loading: true,
  faq: null,
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setFaq: (state, action) => {
      state.faq = action.payload;
    },
    setFaqs: (state, action: PayloadAction<{ faqs: any[]; count: number }>) => {
      const { faqs, count } = action.payload;
      state.faqs = faqs;
      state.count = count;
      state.refreshLoader = false;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    filterfaq: (state, action) => {
      const id = action.payload;
      state.faqs = state.faqs.filter(({ _id }) => _id !== id);
    },

    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },

    updateFaq: (state, action) => {
      const { id, faq } = action.payload;
      state.faqs.every(({ _id }, i) => {
        if (id === _id) {
          state.faqs[i] = faq;
          return false;
        }
        return true;
      });
    },
  },
});

const faqReducer = faqSlice.reducer;

export const faqActions = faqSlice.actions;
export default faqReducer;
