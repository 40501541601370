import { ProductState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: ProductState = {
  deals: [],
  loading: true,
  deal: null,
  count: 0,
  refresh: 0,
  refreshLoader: false,
};

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    setDeal: (state, action) => {
      state.deal = action.payload;
    },
    setDeals: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      const { data, count } = action.payload;
      state.deals = data;
      state.count = count;
      state.refreshLoader = false;
    },
    filterDeals: (state, action) => {
      const id = action.payload;
      state.deals = state.deals.filter(({ _id }) => _id !== id);
    },

    updateDeal: (state, action) => {
      const { id, deal } = action.payload;
      state.deals.every(({ _id }, i) => {
        if (id === _id) {
          state.deals[i] = deal;
          return false;
        }
        return true;
      });
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

const dealReducer = dealSlice.reducer;

export const dealActions = dealSlice.actions;
export default dealReducer;
