import { campaignActions } from "../redux/slices/campaign/campaignSlice";
import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { modalActions } from "redux/slices/modal";
import { formLoaderActions } from "redux/slices/formLoader";

const url = `/compaign`;

const CampaignService = {
  getCampaignbyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { compaign } = success.data.data;
      dispatch?.(campaignActions.setCampaign(compaign));
    }

    dispatch?.(campaignActions.setLoading(false));

    return [success, error];
  },
  getCampaigns: async (filters: any, dispatch?: AppDispatch) => {
    dispatch?.(campaignActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}/filter`, { filters })
    );

    if (success) {
      const { compaigns, count } = success.data.data;
      dispatch?.(campaignActions.setCampaigns({ campaigns: compaigns, count }));
    }

    dispatch?.(campaignActions.setLoading(false));

    return [success, error];
  },
  createCampaign: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      navigate?.("/campaigns");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  deleteCampaign: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      dispatch?.(campaignActions.filtercampaign(id));
    }

    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
  updateCampaign: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      const campaign = success.data.data;
      dispatch?.(campaignActions.updateCampaign({ id, campaign }));
      navigate?.("/campaigns");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    dispatch?.(modalActions.setLoading(false));

    return [success, error];
  },
};

export default CampaignService;
