import { InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { change } from "redux-form";
import { useAppDispatch } from "redux/hooks";
import theme from "theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      // Add your custom input base styles here if needed
    },
    "& .MuiFormHelperText-root": {
      // Add your custom form helper text styles here if needed
    },
  },
}));

export default function SearchSelect({
  options = [],
  InputProps,
  value,
  defaultValue,
  ...rest
}: any) {
  const dispatch = useAppDispatch();
  const classes = useStyles(theme);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSelectChange = (selectedOptions: any) => {
    setSelectedOptions(selectedOptions);
    dispatch?.(change("AddDealForm", "products", selectedOptions));
  };

  useEffect(() => {
    if (defaultValue) setSelectedOptions(defaultValue);
  }, [defaultValue]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#F5F5F5",
      borderRadius: "4px",
      border: "none",
      boxShadow: "none",
      padding: "6px",
    }),
  };

  return (
    <div className={classes.root}>
      <InputLabel shrink htmlFor={rest.name} sx={{ fontWeight: "bold" }}>
        {InputProps.label}
      </InputLabel>
      <AsyncSelect
        // isMulti
        cacheOptions
        {...rest}
        defaultOptions={options}
        value={selectedOptions}
        styles={customStyles}
        placeholder=""
        onChange={handleSelectChange}
      />
      {InputProps.error ? (
        <p
          className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-lfwrwd-MuiFormHelperText-root"
          id="standard-error-helper-text-helper-text"
        >
          {InputProps.helperText}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}
