import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { formLoaderActions } from "redux/slices/formLoader";
import { deliveryFeeActions } from "redux/slices/deliveryFee/deliveryFeeSlice";

const url = `/delivery-fee`;

const DeliveryFeeService = {
  getDeliveryFeebyID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { DeliveryFee } = success.data.data;
      dispatch?.(deliveryFeeActions.setSingleDelivery(DeliveryFee));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getDeliveryFee: async (dispatch?: AppDispatch) => {
    dispatch?.(deliveryFeeActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { deliverFees } = success.data.data;
      dispatch?.(deliveryFeeActions.setDeliveryFee(deliverFees));
    }

    dispatch?.(deliveryFeeActions.setLoading(false));

    return [success, error];
  },
  createDeliveryFee: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      navigate?.("/delivery-fee");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  updateDeliveryFee: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      // const deliveryFee = success.data.data;
      // dispatch?.(deliveryFeeActions.updateDeliveryFee({ id, deliveryFee }));
      navigate?.("/delivery-fee");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default DeliveryFeeService;
