import { Container } from "@mui/material";
import GoBack from "components/atoms/GoBack";
import Banner from "components/templates/Banner";
import BasicCard from "components/templates/BasicCard";
import CampaignService from "services/campaigns.service";
import AddCategoryForm from "./AddCampaignForm";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import UpdateCampaignForm from "./UpdateCampaignForm";
import CircleLoader from "components/atoms/CircleLoader";
import format from "date-fns/format";

export default function AddCampaign() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.startDate = format(
      new Date(data.date.date[0]),
      "yyyy-MM-dd HH:mm:ss.SSSSSS"
    );
    data.endDate = format(
      new Date(data.date.date[1]),
      "yyyy-MM-dd HH:mm:ss.SSSSSS"
    );

    delete data.date;

    if (!data.isActive) data.isActive = data.isActive = "false";

    let { value, discountType } = data;
    data.discount = { value: value, type: discountType };
    delete data.value;
    delete data.discountType;

    if (id) CampaignService.updateCampaign(`${id}`, data, dispatch, navigate);
    else CampaignService.createCampaign(data, dispatch, navigate);
  };

  return (
    <>
      {id ? (
        <BasicCard>
          {loading && <CircleLoader />}
          <AddCategoryForm onSubmit={handleSubmit} />
          <UpdateCampaignForm id={id} />
        </BasicCard>
      ) : (
        <Container maxWidth="lg">
          <GoBack path="/campaigns" title="Campaigns" />
          <Banner heading={`Add New Campaign`}></Banner>
          <BasicCard>
            {loading && <CircleLoader />}
            <AddCategoryForm onSubmit={handleSubmit} />
          </BasicCard>
        </Container>
      )}
    </>
  );
}
