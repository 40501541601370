import { ProductState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
const default_page_size = Number(`${process.env.REACT_APP_PAGE_SIZE}`);

const initialState: ProductState = {
  categories: [],
  loading: true,
  category: null,
  categoriesOptions: [],
  categoryMap: {},
  count: 0,
  refresh: 0,
  refreshLoader: false,
  filter: { page: 1, pageSize: default_page_size },
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setCategories: (
      state,
      action: PayloadAction<{ data: any[]; count: number }>
    ) => {
      const { data, count } = action.payload;
      state.categories = data;
      state.count = count;
      state.refreshLoader = false;
      let options: SelectOption[] = [];

      data?.forEach(({ _id, name }: any) =>
        options.push({ value: _id, label: name })
      );
      state.categories = data;
      state.categoriesOptions = options;

      // let categoryMap: any = {};

      // categories.forEach((store: any) => {
      //   const { _id, name } = store;
      //   categoryMap[_id] = store;
      //   options.push({ value: _id, label: name });
      // });

      // state.categories = categories;
      // state.categoryMap = categoryMap;
      // state.categoriesOptions = options;
    },
    setFilter: (state, action) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter = { ...initialState.filter, ...action.payload };
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filter.page = action.payload;
    },
    resetPage: (state) => {
      state.filter.page = 1;
    },

    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    filterCategory: (state, action) => {
      const id = action.payload;
      state.categories = state.categories.filter(({ _id }) => _id !== id);
    },

    updateCategory: (state, action) => {
      const { id, category } = action.payload;
      state.categories.every(({ _id }, i) => {
        if (id === _id) {
          state.categories[i] = category;
          return false;
        }
        return true;
      });
    },
  },
});

const categoryReducer = categorySlice.reducer;

export const categoryActions = categorySlice.actions;
export default categoryReducer;
