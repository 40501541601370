import { NavigateFunction } from "react-router-dom";
import http from "./http.service";
import { AppDispatch } from "redux/store";
import PromiseAble from "./promiseable.service";
import { formLoaderActions } from "redux/slices/formLoader";
import { searchActions } from "redux/slices/popularSearches/popularSearchesSlice";

const url = `/popular-search`;
const url1 = "user-search";

const SearchService = {
  getSearchByID: async (id: any, dispatch?: AppDispatch) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const { PopularSearches } = success.data.data;
      dispatch?.(searchActions.setSingleSearch(PopularSearches));
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },
  getSearches: async (dispatch?: AppDispatch) => {
    dispatch?.(searchActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { PopularSearches } = success.data.data;
      dispatch?.(searchActions.setSearches(PopularSearches));
    }

    dispatch?.(searchActions.setLoading(false));

    return [success, error];
  },
  getUserSearches: async (dispatch?: AppDispatch) => {
    dispatch?.(searchActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.get(`${url1}`)
    );

    if (success) {
      const { searches } = success.data.data;
      dispatch?.(searchActions.setUserSearches(searches));
    }

    dispatch?.(searchActions.setLoading(false));

    return [success, error];
  },
  createSearch: async (
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await PromiseAble.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      navigate?.("/popular-searches");
    }

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  updateSearch: async (
    id: string,
    data: any,
    dispatch?: AppDispatch,
    navigate?: NavigateFunction
  ) => {
    dispatch?.(formLoaderActions.setLoading(true));
    http.setJWT();
    const [success, error]: any = await PromiseAble.asPromise(
      http.put(`${url}/${id}`, data)
    );
    if (success) {
      // const deliveryFee = success.data.data;
      // dispatch?.(deliveryFeeActions.updateDeliveryFee({ id, deliveryFee }));
      navigate?.("/popular-searches");
    }
    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default SearchService;
