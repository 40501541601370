import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function ConfirmationForm() {
  const dispatch = useAppDispatch();
  const { comp, data } = useAppSelector((state) => state.modal);

  return (
    <div>
      <h3 style={{ marginTop: 0 }}>{data?.heading}</h3>
      {comp}
    </div>
  );
}
